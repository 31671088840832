import { useState } from "react";
import { Modal, Grid, Dropdown, Image } from "semantic-ui-react";
import Comments from "./Comments";
import Actions from "./Actions";
import CommentForm from "./CommentForm";
import "./ModalPublication.scss";
import { DELETE_PUBLICATION } from "../../../gql/publication"
import Swal from 'sweetalert2';
import userAuth from "../../../hooks/useAuth";
import { useMutation } from "@apollo/client";
import UpdateForm from "./UpdateForm";

export default function ModalPublication(props) {
  const { show, setShow, publication } = props;
  const { auth } = userAuth();
  const user = publication.idUser;
  const [publicationDelete] = useMutation(DELETE_PUBLICATION);
  const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState("");
  const [childrenModal, setChildrenModal] = useState(null);


  console.log("Modal: ", publication.carName)
  const onClose = () => setShow(false);

  const onDelete = () => {
    const id = publication.id;
    Swal.fire({
      title: 'Do you want to delete this publication?',
      text: "This action cannot be undone",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete',
      cancelButtonText: 'No, Cancel'
    }).then(async (result) => {
      if (result.value) {

        try {
          // Eliminar por ID
          await publicationDelete({
            variables: {
              id
            }
          })

          // Mostrar una alerta
          Swal.fire(
            'Deleted!',
            'Your publication has been successfully deleted'
          )
          onClose();
        } catch (error) {
          console.log(error);
        }
      }
    })
  }

  const updateForm = () => {

    setTitleModal("Update Your Car Details");
    setChildrenModal(
      <UpdateForm
        setShowModal={setShowModal}
      />
    )
    setShowModal(true);

  }

  return (
    <>
      <Modal id="modalPublicaciones" open={show} onClose={onClose} className="modal-publication" style={{ width: "50%" }}>

        <Grid columns={2}  >
          <Grid.Row style={{ height: "100%" }}>
            <Grid.Column width={8}>
              <Image className="preview-publication__image" src={publication.file} />
            </Grid.Column>
            <Grid.Column width={8}>
              {user === auth.id && (
                <Dropdown icon="ellipsis vertical" className="dropdown">
                  <Dropdown.Menu className="dropdown-menu__right">
                    <Dropdown.Item className="update-item" text="Update" onClick={updateForm} />
                    <Dropdown.Item className="delete-item" text="Delete" onClick={onDelete} />
                  </Dropdown.Menu>
                </Dropdown>
              )}

              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    <p><b>Car Name:  </b>{publication.carName}</p>
                    <p><b>Brand: </b>{publication.brand}</p>
                    <p><b>Category: </b>{publication.category}</p>
                    <p><b>Material:</b>{publication.material}</p>
                    <p><b>Code: </b>{publication.code}</p>
                    <p><b>Scale: </b>{publication.scale}</p>
                    <p><b>Action: <span style={{ color: publication.action === 'Sell' ? 'green' : publication.action === 'Trade' ? '#C63B12' : 'black' }}>{publication.action}</span></b></p>
                    <p><b>Price: </b>{publication.price ? `${publication.price.toLocaleString('es-CO', { style: 'currency', currency: 'COP', minimumFractionDigits: 0 })}` : ""} COP</p>
                    <p><b>Description: </b>{publication.description}</p>
                    <Actions publication={publication} className="actions" />
                  </Grid.Column>
                </Grid.Row>

                <Grid.Column style={{ maxHeight: "400px", width: "100%" }}>
                  <Comments publication={publication} />
                </Grid.Column>
                <Grid.Column style={{ maxHeight: "400px", width: "45%", position: "fixed", bottom: 0 }}>
                  <CommentForm publication={publication} />
                </Grid.Column>
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal>

      <UpdateForm
        show={showModal}
        setShow={setShowModal}
        publication={publication}
      />
    </>

  );
}
