import React from "react";
import { Grid } from "semantic-ui-react";
import { map } from "lodash";
import PreviewPublication from "./PreviewPublication";
import "./Publications.scss";


export default function Publications(props) {
  const { getPublications } = props;
  console.log("Perfil: ", getPublications)

  return (
    <div className="publications">
      <h1>Collection</h1>
      <Grid columns={4}>
        {map(getPublications, (publication, index) => (
          <Grid.Column key={index}>
            <PreviewPublication publication={publication} />
          </Grid.Column>
        ))}
        {/* <div className="preview-publication">
          <img src={getPublications.file} alt={getPublications.carName} />
          <div className="publication-details">
            <p>{getPublications.carName}</p>
            <p>{getPublications.action}</p>
            <p>{getPublications.brand}</p>
            <p>{getPublications.material}</p>
          </div>
        </div> */}
      </Grid>
    </div >

  );
}
