import { gql } from "@apollo/client";

export const PUBLISH = gql`
  mutation publish( $input: PublicationInput ) {
    publish(input: $input) {
      status
      urlFile
    }
  }
`;

export const GET_PUBLICATIONS = gql`
  query getPublications($username: String!) {
    getPublications(username: $username) {
      id
      idUser
      file
      typeFile
      carName
      brand
      material
      code
      price
      action
      description
      scale
      category
    }
  }
`;

export const GET_PUBLICATIONS_FOLLOWEDS = gql`
  query getPublicationsFolloweds {
    getPublicationsFolloweds {
      id
      idUser {
        username
        name
        email
        avatar
      }
      file
      typeFile
      createAt
      carName
      brand
      code
      price
      material
      action
      description
      scale
      category
    }
  }
`;

export const DELETE_PUBLICATION = gql` 
mutation publicationDelete($id: ID!){
publicationDelete(id:$id)
}
`;

export const UPDATE_PUBLICATION = gql`
  mutation publicationUpdate($id: ID!, $input:PublicationUpdate){
  publicationUpdate(id: $id, input:$input){
    carName
    brand
    material
    code
    price
    action
    description
}
}
`;
