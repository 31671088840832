import React, { useState } from "react";
import { Image } from "semantic-ui-react";
import ModalPublication from "../../Modal/ModalPublication";
import "./PreviewPublication.scss";

export default function PreviewPublication(props) {
  const { publication } = props;
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <div className="preview-publication" onClick={() => setShowModal(true)}>
        <Image className="preview-publication__image" src={publication.file} />
        <div className="preview-publication__details">
          <p><b>Car Name: </b>{publication.carName}</p>
          <p><b>Brand: </b>{publication.brand}</p>
          <p><b>Category: </b>{publication.category}</p>
          <p><b>Material:</b>{publication.material}</p>
          <p><b>Code: </b>{publication.code}</p>
          <p><b>Scale: </b>{publication.scale}</p>
          <p><b>Action: <span style={{ color: publication.action === 'Sell' ? 'green' : publication.action === 'Trade' ? '#C63B12' : 'black' }}>{publication.action}</span></b></p>
          <p><b>Price: </b>{publication.price ? `${publication.price.toLocaleString('es-CO', { style: 'currency', currency: 'COP', minimumFractionDigits: 0 })}` : ""} COP</p>
        </div>
      </div>

      <ModalPublication
        show={showModal}
        setShow={setShowModal}
        publication={publication}

      />
    </>
  );
}
