import React, { useState } from "react";
import { Container, Image } from "semantic-ui-react";
import RegisterForm from "../../components/Auth/RegisterForm";
import LoginForm from "../../components/Auth/LoginForm";
import instaclone from "../../assets/png/instaclone.png";
import "./Auth.scss";

export default function Auth() {
  const [showLogin, setShowLogin] = useState(true);

  return (
    <Container fluid className="auth">
      {/* <Image src={instaclone}  size='large'/> */}
      <div className="titles">
        <h1>TROLY</h1>
        <p>Connect.</p>
        <p>Organize.</p>
        <p>Enjoy your collection.</p>
      </div>

      <div className="container-form">
        {showLogin ? (
          <LoginForm />
        ) : (
          <RegisterForm setShowLogin={setShowLogin} />
        )}
      </div>

      <div className="change-form">
        <p>
          {showLogin ? (
            <>
              Not a member yet?
              <span onClick={() => setShowLogin(!showLogin)}>Sign up now</span>
            </>
          ) : (
            <>
              Sign in with your account!
              <span onClick={() => setShowLogin(!showLogin)}>
                Log In
              </span>
            </>
          )}
        </p>
      </div>
    </Container>
  );
}
