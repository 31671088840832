import React, { useState } from "react";
import "./UpdateForm.scss";
import { Modal, Button, Form, TextArea, Input } from "semantic-ui-react";
import { useFormik } from "formik";
import { useMutation } from "@apollo/client";
import * as Yup from "yup";
import { UPDATE_PUBLICATION } from "../../../../gql/publication";
import { toast } from "react-toastify";

export default function UpdateForm(props) {
    const { publication, show, setShow, title } = props


    const onClose = () => {
        setShow(false);
    };

    const [updatePublication] = useMutation(UPDATE_PUBLICATION);
    const id = publication.id;
    const carName = publication.carName;
    const brand = publication.brand;
    const material = publication.material;
    const action = publication.action;
    const price = publication.price;
    const code = publication.code;
    const description = publication.description;
    const scale = publication.scale;
    const category = publication.category;

    const formik = useFormik({
        initialValues: {

            carName: carName || "",
            price: price || "",
            brand: brand || "",
            action: action || "",
            material: material || "",
            code: code || "",
            description: description || "",
            scale: scale || "",
            category: category || "",

        },
        validationSchema: Yup.object({
            carName: Yup.string().required('The name is required'),
            description: Yup.string().required('The description is required'),
            brand: Yup.string().required('The brand is required'),
            material: Yup.string().required('The material is required'),
            price: Yup.number().required('The price is required'),
            action: Yup.string().required('The action is required'),
            code: Yup.string().required('The code is required'),
            scale: Yup.string().required('The scale is required'),
            category: Yup.string().required('The category is required'),
        }),
        onSubmit: async (input) => {

            try {
                const publication = input;
                console.log(input)
                console.log(publication.carName);

                await updatePublication({
                    variables: {
                        id,
                        input,

                    }
                })
                setShow(false);
                return publication;

            } catch (error) {
                console.log(error);
            }

        },
    })

    return (
        <Modal title={title} size="small" open={show} onClose={onClose} className="modal-update">
            <Form className="description-form" onSubmit={formik.handleSubmit}>
                <Form.Input
                    label="Car Name"
                    className="formInput"
                    type="text"
                    placeholder="Car name"
                    name="carName"
                    value={formik.values.carName}
                    onChange={formik.handleChange}
                    error={formik.errors.carName && true}
                />

                <Form.Field>
                    <label>Brand</label>
                    <select
                        name="brand"
                        onChange={formik.handleChange}
                        defaultValue="Select"
                    >
                        <option disabled>Select</option>
                        <option>Auto Art</option>
                        <option>Auto World</option>
                        <option>Bburago</option>
                        <option>Burago</option>
                        <option>Corgi</option>
                        <option>Franklin Mint</option>
                        <option>Greenlight Collectibles</option>
                        <option>Hot Wheels</option>
                        <option>Jada Toys</option>
                        <option>Kyosho</option>
                        <option>Maisto</option>
                        <option>Majorette</option>
                        <option>Matchbox</option>
                        <option>M2 Machines</option>
                        <option>Norev</option>
                        <option>Revell</option>
                        <option>Schuco</option>
                        <option>Solido</option>
                        <option>Tamiya</option>
                        <option>Tomica</option>
                        <option>Welly</option>
                    </select>
                    {formik.errors.brand && formik.touched.brand && (
                        <div className="error">{formik.errors.brand}</div>
                    )}
                </Form.Field>

                <Form.Field>
                    <label>Category</label>
                    <select
                        name="category"
                        onChange={formik.handleChange}
                        defaultValue="Select"
                    >
                        <option disabled>Select</option>
                        <option>American Muscle</option>
                        <option>Classic Muscle</option>
                        <option>Concept Cars</option>
                        <option>Custom Cars</option>
                        <option>Dragsters</option>
                        <option>Electric Cars</option>
                        <option>Exotics</option>
                        <option>Formula 1</option>
                        <option>Hot Rods</option>
                        <option>IndyCar</option>
                        <option>JDM</option>
                        <option>Movie & TV Cars</option>
                        <option>NASCAR</option>
                        <option>Off-Road</option>
                        <option>Police & Fire</option>
                        <option>Race Cars</option>
                        <option>Rally Cars</option>
                        <option>Supercars</option>
                        <option>Trucks & SUVs</option>
                        <option>Vintage Cars</option>
                        <option>Other</option>
                    </select>
                    {formik.errors.category && formik.touched.category && (
                        <div className="error">{formik.errors.category}</div>
                    )}
                </Form.Field>

                <Form.Field>
                    <label>Material</label>
                    <select
                        name="material"
                        onChange={formik.handleChange}
                        defaultValue="Select"
                    >
                        <option disabled>Select</option>
                        <option>Aluminum</option>
                        <option>Ceramic</option>
                        <option>Die-cast metal</option>
                        <option>Fiberglass</option>
                        <option>Paper</option>
                        <option>Plastic</option>
                        <option>Resin</option>
                        <option>Rubber</option>
                        <option>Vinyl</option>
                        <option>Wood</option>
                    </select>
                    {formik.errors.material && formik.touched.material && (
                        <div className="error">{formik.errors.material}</div>
                    )}
                </Form.Field>

                <Form.Field>
                    <label>Action</label>
                    <select
                        name="action"
                        onChange={formik.handleChange}
                        defaultValue="Select"
                    >
                        <option disabled>Select</option>
                        <option>Share</option>
                        <option>Sell</option>
                        <option>Trade</option>
                    </select>
                    {formik.errors.action && formik.touched.action && (
                        <div className="error">{formik.errors.action}</div>
                    )}
                </Form.Field>

                <Form.Field>
                    <label>Scale</label>
                    <select
                        name="scale"
                        onChange={formik.handleChange}
                        defaultValue="Select"
                    >
                        <option disabled>Select</option>
                        <option>1/18</option>
                        <option>1/24</option>
                        <option>1/32</option>
                        <option>1/43</option>
                        <option>1/64</option>
                        <option>1/87</option>
                    </select>
                    {formik.errors.scale && formik.touched.scale && (
                        <div className="error">{formik.errors.scale}</div>
                    )}
                </Form.Field>

                <Form.Input
                    label="Price"
                    className="formInput"
                    type="number"
                    placeholder="Price in Colombian Pesos"
                    name="price"
                    value={formik.values.price}
                    onChange={formik.handleChange}
                    error={formik.errors.price && true}
                />

                <Form.Input
                    label="Code"
                    className="formInput"
                    type="text"
                    placeholder="Code"
                    name="code"
                    value={formik.values.code}
                    onChange={formik.handleChange}
                    error={formik.errors.code && true}
                />
                <Form.TextArea
                    label="Description"
                    className="formInput"
                    id="formInput-description"
                    type="text"
                    placeholder="Description"
                    name="description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    error={formik.errors.description && true}
                />
                <Button type="submit" className="btn-submit" onClose={onClose}>
                    Update
                </Button>
            </Form>
        </Modal>
    );
}