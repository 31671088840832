import React from "react";
import "./Error404.scss";

export default function Error404() {
  return (
    <div>
      <h1>Error404...</h1>
    </div>
  );
}
