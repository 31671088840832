import React, { useState, useCallback } from "react";
import { Modal, Icon, Button, Dimmer, Loader } from "semantic-ui-react";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import { useMutation } from "@apollo/client";
import { PUBLISH } from "../../../gql/publication";
import "./ModalUpload.scss";
import { Form } from "semantic-ui-react";
import { useFormik } from "formik";
import * as Yup from "yup";



export default function ModalUpload(props) {
  const { show, setShow } = props;
  const [fileUpload, setFileUpload] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [publish] = useMutation(PUBLISH);

  const onDrop = useCallback((acceptedFile) => {
    const file = acceptedFile[0];
    setFileUpload({
      type: "image",
      file,
      preview: URL.createObjectURL(file),
    });
  });

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png",
    noKeyboard: true,
    multiple: false,
    onDrop,
  });

  const onClose = () => {
    setIsLoading(false);
    setFileUpload(null);
    setShow(false);
  };

  const onPublish = async () => {
    try {
      if (!formik.isValid) { // Verifica si el formulario es válido
        toast.warning("Please fill in all required fields");
        return;
      }

      setIsLoading(true);

      //Prueba formato precio
      // const formatter = new Intl.NumberFormat('es-CO', {
      //   style: 'currency',
      //   currency: 'COP'
      // });

      const result = await publish({
        variables: {
          input: {
            file: fileUpload.file,
            carName: formik.values.carName,
            description: formik.values.description,
            brand: formik.values.brand,
            material: formik.values.material,
            price: formik.values.price,
            action: formik.values.action,
            code: formik.values.code,
            scale: formik.values.scale,
            category: formik.values.category,

          },
        },
      });

      const { data } = result;

      if (!data.publish.status) {
        toast.warning("Error en la publicación");
        isLoading(false);
      } else {
        onClose();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const formik = useFormik({
    initialValues: {
      carName: '',
      price: '',
      action: '',
      description: '',
      brand: '',
      material: '',
      code: '',
      scale: '',
      category: '',
    },
    validationSchema: Yup.object({
      carName: Yup.string().required('The name is required'),
      description: Yup.string().required('The description is required'),
      brand: Yup.string().required('The brand is required'),
      material: Yup.string().required('The material is required'),
      price: Yup.number().required('The price is required'),
      action: Yup.string().required('The action is required'),
      code: Yup.string().required('The code is required'),
      scale: Yup.string().required('The scale is required'),
      category: Yup.string().required('The category is required'),
    }),
    onSubmit: (values) => {
      // console.log(values);
    },
  });


  return (

    <Modal size="large" open={show} onClose={onClose} className="modal-upload"  >
      <div className="publicationInfo">
        <h1>Car Details</h1>

        <Form className="upload-form">

          <Form.Input
            label="Car Name"
            className="formInput"
            type="text"
            placeholder="Car name"
            name="carName"
            value={formik.values.carName}
            onChange={formik.handleChange}
            error={formik.errors.carName && true}
          />

          <Form.Field>
            <label>Brand</label>
            <select
              name="brand"
              onChange={formik.handleChange}
              defaultValue="Select"
            >
              <option disabled>Select</option>
              <option>Auto Art</option>
              <option>Auto World</option>
              <option>Bburago</option>
              <option>Burago</option>
              <option>Corgi</option>
              <option>Franklin Mint</option>
              <option>Greenlight Collectibles</option>
              <option>Hot Wheels</option>
              <option>Jada Toys</option>
              <option>Kyosho</option>
              <option>Maisto</option>
              <option>Majorette</option>
              <option>Matchbox</option>
              <option>M2 Machines</option>
              <option>Norev</option>
              <option>Revell</option>
              <option>Schuco</option>
              <option>Solido</option>
              <option>Tamiya</option>
              <option>Tomica</option>
              <option>Welly</option>
            </select>
            {formik.errors.brand && formik.touched.brand && (
              <div className="error">{formik.errors.brand}</div>
            )}
          </Form.Field>

          <Form.Field>
            <label>Category</label>
            <select
              name="category"
              onChange={formik.handleChange}
              defaultValue="Select"
            >
              <option disabled>Select</option>
              <option>American Muscle</option>
              <option>Classic Muscle</option>
              <option>Concept Cars</option>
              <option>Custom Cars</option>
              <option>Dragsters</option>
              <option>Electric Cars</option>
              <option>Exotics</option>
              <option>Formula 1</option>
              <option>Hot Rods</option>
              <option>IndyCar</option>
              <option>JDM</option>
              <option>Movie & TV Cars</option>
              <option>NASCAR</option>
              <option>Off-Road</option>
              <option>Police & Fire</option>
              <option>Race Cars</option>
              <option>Rally Cars</option>
              <option>Supercars</option>
              <option>Trucks & SUVs</option>
              <option>Vintage Cars</option>
              <option>Other</option>
            </select>
            {formik.errors.category && formik.touched.category && (
              <div className="error">{formik.errors.category}</div>
            )}
          </Form.Field>

          <Form.Field>
            <label>Material</label>
            <select
              name="material"
              onChange={formik.handleChange}
              defaultValue="Select"
            >
              <option disabled>Select</option>
              <option>Aluminum</option>
              <option>Ceramic</option>
              <option>Die-cast metal</option>
              <option>Fiberglass</option>
              <option>Paper</option>
              <option>Plastic</option>
              <option>Resin</option>
              <option>Rubber</option>
              <option>Vinyl</option>
              <option>Wood</option>
            </select>
            {formik.errors.material && formik.touched.material && (
              <div className="error">{formik.errors.material}</div>
            )}
          </Form.Field>

          <Form.Field>
            <label>Action</label>
            <select
              name="action"
              onChange={formik.handleChange}
              defaultValue="Select"
            >
              <option disabled>Select</option>
              <option>Share</option>
              <option>Sell</option>
              <option>Trade</option>
            </select>
            {formik.errors.action && formik.touched.action && (
              <div className="error">{formik.errors.action}</div>
            )}
          </Form.Field>

          <Form.Field>
            <label>Scale</label>
            <select
              name="scale"
              onChange={formik.handleChange}
              defaultValue="Select"
            >
              <option disabled>Select</option>
              <option>1/18</option>
              <option>1/24</option>
              <option>1/32</option>
              <option>1/43</option>
              <option>1/64</option>
              <option>1/87</option>
            </select>
            {formik.errors.scale && formik.touched.scale && (
              <div className="error">{formik.errors.scale}</div>
            )}
          </Form.Field>

          <Form.Input
            label="Price"
            className="formInput"
            type="number"
            placeholder="Price in Colombian Pesos"
            name="price"
            value={formik.values.price}
            onChange={formik.handleChange}
            error={formik.errors.price && true}
          />

          <Form.Input
            label="Code"
            className="formInput"
            type="text"
            placeholder="Code"
            name="code"
            value={formik.values.code}
            onChange={formik.handleChange}
            error={formik.errors.code && true}
          />
          <Form.Input
            label="Description"
            className="formInput"
            id="formInput-description"
            type="text"
            placeholder="Description"
            name="description"
            value={formik.values.description}
            onChange={formik.handleChange}
            error={formik.errors.description && true}
          />

          <div
            {...getRootProps()}
            className="dropzone"
            style={fileUpload && { border: 0 }}
          >
            {!fileUpload && (
              <>
                <Icon name="cloud upload" style={{
                  fontSize: "5em", // ajusta el tamaño del icono según sea necesario
                  position: "fixed", // coloca el icono encima del texto
                  top: "auto", // ajusta la posición vertical del icono según sea necesario
                }} />
                <div style={{ marginTop: "175px", textAlign: "center" }}>
                  <p>Drag and drop the photo you want to publish here</p>
                  <p style={{ fontSize: "12px" }}><b>To ensure smooth uploading, please limit your images to 1MB or less</b></p>
                </div>
              </>
            )}
            <input {...getInputProps()} />
          </div>
        </Form>
      </div>

      {fileUpload?.type === "image" && (

        <Form>
          <div
            //class="formUpload-div"
            className="image"
            style={{ backgroundImage: `url("${fileUpload.preview}")` }}
          />
        </Form>
      )}

      {fileUpload && (
        <>
          <Button className="btn-upload btn-action" onClick={onPublish}>
            Publish
          </Button>
        </>
      )}

      {isLoading && (
        <Dimmer active className="publishing">
          <Loader />
          <p>Publishing...</p>
        </Dimmer>
      )}
    </Modal>
  );
}